const BackIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 9.99997L3.293 10.707L2.586 9.99997L3.293 9.29297L4 9.99997ZM21 18C21 18.2652 20.8946 18.5195 20.7071 18.7071C20.5196 18.8946 20.2652 19 20 19C19.7348 19 19.4804 18.8946 19.2929 18.7071C19.1054 18.5195 19 18.2652 19 18H21ZM8.293 15.707L3.293 10.707L4.707 9.29297L9.707 14.293L8.293 15.707ZM3.293 9.29297L8.293 4.29297L9.707 5.70697L4.707 10.707L3.293 9.29297ZM4 8.99997H14V11H4V8.99997ZM21 16V18H19V16H21ZM14 8.99997C15.8565 8.99997 17.637 9.73747 18.9497 11.0502C20.2625 12.363 21 14.1435 21 16H19C19 14.6739 18.4732 13.4021 17.5355 12.4644C16.5979 11.5268 15.3261 11 14 11V8.99997Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default BackIcon;
